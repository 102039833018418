<template>
<v-form @submit.prevent="sendPasswordClicked()" v-model="validData">
  <v-text-field
    label="Contraseña"
    v-model="newPassword"
    :type="showPassword ? 'text' : 'password'"
    :rules="rules.password"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPassword = !showPassword">
  </v-text-field>
  <v-btn color="primary" :disabled="!validData" @click="sendPasswordClicked()" block>Enviar</v-btn>
  <v-dialog v-model="okDialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">Contraseña establecida</v-card-title>
        <v-card-text>Ya puedes ingresar con tu nueva contraseña</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" :loading="isLoading" text @click="goToLogin()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="snackbar">
      {{snackbarText}}
      <v-btn text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
</v-form>
</template>

<script>
import rules from '@/constants/input-validation'
import { USERS_SET_PASSWORD /* USERS_VERIFY_TOKEN */ } from '@/store/actions.type'
import { mapActions } from 'vuex'

export default {
  /* created() {
    const token = this.$route.params.token
    this.verifyToken(token)
      .then(reponse => {
        console.log(reponse)
      })
      .catch(e => {
        console.log('error' + e)
        this.$router.push('/session/login')
      })
  }, */
  name: 'password-setter',
  props: ['title', 'buttons'],
  data() {
    return {
      isLoading: false,
      error: null,
      validData: false,
      newPassword: '',
      showPassword: false,
      label: 'Establece tu contraseña',
      snackbarText: '',
      okDialog: false,
      snackbar: false,
      rules
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      setPassword: USERS_SET_PASSWORD
      /* verifyToken: USERS_VERIFY_TOKEN */
    }),
    routeTo(urlSlug) {
      this.$router.push(urlSlug)
    },
    sendPasswordClicked() {
      const resetPasswordToken = this.$route.params.token
      const newPassword = this.newPassword
      const params = { resetPasswordToken, newPassword }
      this.setPassword(params)
        .then(reponse => {
          this.isLoading = false
          this.okDialog = true
        })
        .catch(e => {
          this.snackbar = true
          this.$router.push('/session/login')
          this.snackbarText = 'El token de actualización es incorrecto o está caducado.'
        })
    },
    goToLogin() {
      this.isLoading = true
      setTimeout(() => {
        this.$router.push('/password-confirm')
      }, 500)
    }
  },
  components: {}
}
</script>

<style>
</style>
